jQuery(function() {

	// mobile menu
	$('#main-menu').slb_mobile_drop_menu('md');

	// function formGracias(){
	// 	if(window.location.hash == '#contact-form') {
	// 		$('.sec-contacto .buttons').after('<p>Gracias por contactarnos</p>');
	// 	}
	// }

	function scrollPage() {

		jQuery('#mnu-contacto a').on('click',
			function(e) {
				e.preventDefault();

				var target = this.hash;
				var $target = $(target);

				jQuery('html, body').stop().animate({
					'scrollTop' : $target.offset().top
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			});
	}


	
	jQuery(function() {
		if($('#salsalab-slider-h .swiper-slide').length > 1)
		{
			var homeswiper = new Swiper('#salsalab-slider-h', {
				direction           : 'horizontal',
				auto: true,
				loop: true,
				slidesPerView				: 1,
				keyboardControl     : true,
				pagination          : '#salsalab-slider-h .swiper-pagination',
				paginationClickable : true,
				autoplay: 4000,
				breakpoints: {
					// when window width is <= 320px
					320: {
					  slidesPerView: 1, 
					  spaceBetween: 10
					},
					// when window width is <= 480px
					480: {
					  slidesPerView: 1,
					  spaceBetween: 20
					},
					// when window width is <= 640px
					640: {
					  slidesPerView: 1,
					  spaceBetween: 30
					}
				  }
			});
		}
	})

	function contactOrder() {

		if($('.sec-contacto .contact-form').length > 0) {

			jQuery('.sec-contacto .contact-form').before('<div class="contact-top animate__animated animate__fadeIn" data-wow-duration="3s"><div class="social-icons"><div class="d-flex"><a class="footer-sm-fb ico" href="https://www.facebook.com/yellowcoatings" target="_blank" data-ico=""></a><a class="footer-sm-ins ico" href="https://www.instagram.com/yellowcoatings/?hl=es-la" target="_blank" data-ico=""></a><a class="footer-sm-in ico" href="https://www.linkedin.com/company/yellow-coatings/" target="_blank" data-ico=""></a></div></div><br><p class="caption-contact">Yellow Coating se encuentra justo en el corazón del Bajío, a 34 km de León, 17 minutos de Irapuato y 10 minutos de Silao, Centro.</p><br><h2>CONTÁCTANOS</h2><p>Parque Industrial Logistics, Los Infantes, 36270, Silao, Guanajuato. <br><br>· Teléfono: +52 473 738 4327<br>· Correo: info@yellowcoatings.com</p></div>');
			jQuery('.sec-contacto .contact-top').append($(".subtitle-contact"));
			jQuery('.sec-contacto .contact-top').append($(".title-contact"));			
			jQuery('.sec-contacto .contact-top').append($(".text-contact"));

			jQuery('.sec-contacto .contact-top').append($(".text-contact-title-ubicacion"));
			jQuery('.sec-contacto .contact-top').append($(".text-contact-text-ubicacion"));			
		}
	}

	function formMessage() {
		var form = $('#contact-form');
    form.submit(function(e) {
        // prevent form submission

				$('#contacto').addClass("onload");

        e.preventDefault();
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            dataType: 'html',
            data: form.serialize(),
            success: function(result) {
                $('#contacto .content-form ').append('<p id="msg-contact" class="msg-succes">Su mensaje se ha enviado exitosamente y pronto será contactado</p>');
								$('#contacto').removeClass("onload");
            }
        });
    });
	}

	scrollPage();
	contactOrder();
	formMessage();

});



//WAYPOINTS
jQuery(document).ready(function() {

	if ($.fn.waypoint) {

		function showPoints() {

			jQuery('.sec-conexiones').waypoint( {
				handler: function() {
					$(this).find('#andari-lupa').addClass('on-item');
					$(this).find('#andari-personas').addClass('on-item');
					$(this).find('#andari-escaleras').addClass('on-item');
				},
				offset: '66%'
			});


			jQuery('.subtitle-line').waypoint( {
				handler: function() {
					$(this).addClass('flash-ba');
				},
				offset: '66%'
			});

			jQuery('#contacto').waypoint( {
				handler: function() {
					$(this).addClass('on-item');
				},
				offset: '66%'
			});


			jQuery('#home-servicios').waypoint( {
				handler: function() {
					$(this).find('.col-lg-6').addClass('on-item');
				},
				offset: '66%'
			});


			jQuery('#somos-valores').waypoint( {
				handler: function() {
					$(this).find('li').addClass('on-item');
				},
				offset: '66%'
			});



			jQuery('.bg-services-onda').waypoint( {
				handler: function() {
					$(this).find('li').addClass('on-item');
				},
				offset: '66%'
			});

		}

	}

		showPoints();
});


//SEARCH
jQuery(document).ready(function() {
	$("#mnu-Search").click(function(){
		$('.search-wrapper').toggleClass('show-search');
	  });
});


//SLIDER PERMITE SCRILLEAR
$(function(){
	$('#home-slider').on('mousewheel', function(e){
		$( this ).scroll();
	});
	
  });